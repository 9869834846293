@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply absolute invisible;
}

text.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}
.has-tooltip:hover .tooltip {
  @apply z-50 visible;
  background: #f9fafb;
  position: absolute;
  top: -38px;
  min-width: 100px;
  right: 0;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  color: #6b7280;
  padding: 5px 10px;
  white-space: pre;
  border-radius: 5px;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 10%),
    0 1px 2px 0 rgb(0 0 0 / 6%);
}
.has-tooltip:hover .tooltip:after {
  content: "";
  display: block;
  position: absolute;
  right: 20px;
  top: 100%;
  height: 0;
  border-bottom: 8px solid transparent;
  border-top: 8px solid #d1d5db;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
